import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS, MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Commons } from '../../../services/common.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})

export class BottomSheetComponent {
  private data: any;

  constructor(
    private common: Commons,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) param: any) {
      this.data = param;
    }

  cancel(): void {
    this.bottomSheetRef.dismiss({});
    event.preventDefault();
  }

  add(): void {
    this.bottomSheetRef.dismiss(this.data);
    event.preventDefault();
  }
}
