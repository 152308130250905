import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Select2Module } from 'ng2-select2';
import { MaterialModule } from './material.module';

import { FooterComponent } from '../components/shared/footer/footer.component';
import { NavbarComponent } from '../components/shared/navbar/navbar.component';
import { SidebarComponent } from '../components/shared/sidebar/sidebar.component';
import { DialogComponent } from '../components/shared/dialog/dialog.component';
import { BottomSheetComponent } from '../components/shared/bottom-sheet/bottom-sheet.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    Select2Module,
    MaterialModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    DialogComponent,
    BottomSheetComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent
  ],
  entryComponents: [
    DialogComponent,
    BottomSheetComponent
  ]
})
export class ComponentsModule { }
