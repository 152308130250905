import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
    private title: string;
    private message: string;
    private buttonColor: string;
    private actionText: string;

    constructor(
      private dialogRef: MatDialogRef<DialogComponent>,
      @Inject(MAT_DIALOG_DATA) data) {
          this.title = data.title;
          this.message = data.message;
          this.buttonColor = 'btn-' + data.buttonColor;
          this.actionText = data.actionText;
    }

  save() {
      this.dialogRef.close();
  }

  close() {
      this.dialogRef.close();
  }
}
