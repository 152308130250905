import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}
export const ROUTES: RouteInfo[] = [
    { path: '/npi-simulation', title: 'NPI Simulation',  icon: 'insert_chart', class: '' }
];
export const ROUTES_SIMULATION: RouteInfo[] = [
    { path: '/annual-demand-upload', title: 'Annual Demand Upload',  icon: 'assignment_returned', class: '' },
    { path: '/exchange-rate', title: 'Exchange Rate',  icon: 'attach_money', class: '' },
    { path: '/ink-family-type', title: 'Ink Family Type',  icon: 'opacity', class: '' },
    { path: '/supplier-capabilities', title: 'Supplier\'s Capabilities',  icon: 'assignment', class: '' }
];
export const ROUTES_REFERENCEDATA: RouteInfo[] = [
    { path: '/sku-master', title: 'SKU Master',  icon: 'assignment', class: '' },
    { path: '/profile', title: 'Profile',  icon: 'assignment_ind', class: '' },
    { path: '/machine', title: 'Machine',  icon: 'domain', class: '' }
];
export const ROUTES_RAWMATERIALS: RouteInfo[] = [
    { path: '/foil', title: 'Foil',  icon: 'domain', class: '' },
    { path: '/ink', title: 'Ink',  icon: 'opacity', class: '' },
    { path: '/substrate', title: 'Substrate',  icon: 'layers', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menus: any[];
  menus_simulations: any[];
  menus_referencedatas: any[];
  menus_rawmaterials: any[];
  private user = {
    name: 'Guest',
    email: 'guest@demo.com',
    role: 'Manager'
};

  constructor(private router: Router) { }

  ngOnInit() {
    this.menus = ROUTES.filter(menu => menu);
    this.menus_simulations = ROUTES_SIMULATION.filter(menu_simulations => menu_simulations);
    this.menus_referencedatas = ROUTES_REFERENCEDATA.filter(menu_referencedatas => menu_referencedatas);
    this.menus_rawmaterials = ROUTES_RAWMATERIALS.filter(menu_rawmaterials => menu_rawmaterials);
  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  }
  LogOff() {
      this.router.navigate(['login']);
  }
}
