import { Component, AfterViewInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {
  constructor(private router: Router,
    private ngxService: NgxUiLoaderService
  ) { }

  ngAfterViewInit() {
    this.router.events
        .subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.ngxService.start();
            } else if (
                event instanceof NavigationEnd || event instanceof NavigationCancel
                ) {
                  this.ngxService.stop();
            }
        });
  }
}
