import { Injectable } from '@angular/core';
import { Select2OptionData, Select2TemplateFunction } from 'ng2-select2';
import { OrderPipe } from 'ngx-order-pipe';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material';
import { DialogComponent } from '../components/shared/dialog/dialog.component';
import { UUID } from 'angular2-uuid';

@Injectable({
  providedIn: 'root'
})

export class Commons {
  private dialogRef: MatDialogRef<DialogComponent>;

  public dateDisplayFormat = 'dd/MM/yyyy';

  public pagination = {
    LENGTH: '100',
    SIZE: '10',
    SIZEOPTIONS: [5, 10, 25, 100]
  };

  public smallPagination = {
    LENGTH: '10',
    SIZE: '5',
    SIZEOPTIONS: [5, 10]
  };

  public select2 = {
    defaultValue: [{ id: '', text: '' }],
    Options: (data: Array<Select2OptionData>, multiple: boolean = false, allowClear: boolean = true) => {
      return {
        data: this.RemoveDuplicateSelect2(data),
        allowClear: allowClear,
        multiple: multiple,
        placeholder: {
          id: '',
          text: (multiple ? 'Choose ' : 'Select ') + '...'
        }
      };
    }
  };

  constructor(private orderPipe: OrderPipe,
    private dialog: MatDialog
    ) { }

  public MinDate (data: any, sort: string): Date {
    return data.reduce((m, v, i) => (v[sort] < m[sort])
      && i ? v : m)[sort];
  }

  public MaxDate (data: any, sort: string): Date {
    return data.reduce((m, v, i) => (v[sort] > m[sort])
      && i ? v : m)[sort];
  }

  public ApplyDefaultSorting(property: any, defaultSort: string[]) {
    return this.orderPipe.transform(property, defaultSort);
  }

  private RemoveDuplicateSelect2 = (data: Array<Select2OptionData>) => {
    data = data.filter((x, i, self) =>
      i === self.findIndex((t) => (
        t.id === x.id
      ))
    );
    return data;
  }

  public ShowDialog(title: string, message: string, buttonColor: string = 'success', actionText: string = 'OK'): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: title,
      message: message,
      buttonColor: buttonColor,
      actionText: actionText
    };

    this.dialogRef = this.dialog.open(DialogComponent, dialogConfig);
  }

  public GenerateUUID() {
    return UUID.UUID();
  }
}
