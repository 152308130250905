import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app.routing';
import { Select2Module } from 'ng2-select2';
import { NgxUiLoaderModule, NgxUiLoaderConfig  } from 'ngx-ui-loader';
import { CustomPreloadingStrategy } from './services/preloading.service';
import { AmChartsModule } from '@amcharts/amcharts3-angular';

import { MaterialModule } from './modules/material.module';
import { ComponentsModule } from './modules/components.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';

import {
  DatePipe,
  DecimalPipe,
  PercentPipe
} from '@angular/common';
import { OrderPipe } from 'ngx-order-pipe';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  blur: 5,
  fgsColor: '#7d90fb',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'three-strings',
  gap: 24,
  masterLoaderId: '',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(40, 40, 40, 0.8)',
  pbColor: '#7d90fb',
  pbDirection: 'ltr',
  pbThickness: 2,
  hasProgressBar: true
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ComponentsModule,
    RouterModule,
    HttpClientModule,
    AppRoutingModule,
    Select2Module,
    MaterialModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AmChartsModule
  ],
  declarations: [
    AppComponent,
    LayoutComponent,
    LoginComponent,
    ForgotPasswordComponent,
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    PercentPipe,
    OrderPipe,
    CustomPreloadingStrategy
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
